import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../service';
import { Router, ActivatedRoute } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, IonRouterOutlet } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { NewPageSelectComponent } from '../shared/components/new-page-select/new-page-select.component';
import { Platform } from '@ionic/angular';

@Component({
	selector: 'app-my-title',
	templateUrl: './my-title.page.html',
	styleUrls: ['./my-title.page.scss'],
})
export class MyTitlePage implements OnInit {
	@ViewChild(IonRouterOutlet) ionRouterOutlet: IonRouterOutlet;
	isShow = false;
	@Input() titleContent: any;
	isH5 = JSON.parse(this.localStorageService.getStore('isH5'));
	isWX = window.location.href.search('wx-') >= 0 ? true : false;
	@Input() isHome: boolean;
	@Input() canBack: boolean;
	@Input() titleContentStr: any;

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public navController: NavController,
		public modalController: ModalController,
		public localStorageService: LocalStorageService,
		private platform: Platform,
		private geolocation: Geolocation) {
		this.activatedRoute.params.subscribe(param => {

		});
	}



	ngOnInit() {
		// 设置标题
		// document.getElementsByTagName('title').item(0).innerText = '爱玛客';

		// if (dd.env.platform !== 'notInDingTalk') {
		// 	dd.biz.navigation.setTitle({
		// 		title: '爱玛客', // 钉钉设置导航栏标题
		// 	});
		// }
		// if (this.platform.is('android')) {
		// 	console.log(this.platform['win'].Ionic.mode);
		// 	console.log(this.platform['win'].Ionic.platforms);
		// } else if (this.platform.is('ios')) {
		// 	console.log(this.platform['win'].Ionic.mode);
		// 	console.log(this.platform['win'].Ionic.platforms);
		// } else {
		// 	console.log(this.platform['win'].Ionic.mode);
		// 	console.log(this.platform['win'].Ionic.platforms);
		// }
		//  
		if (this.isH5 || this.isWX) {  // 企业微信入口，不给title
			this.isShow = false;
		} else {
			this.isShow = true;
		}
	}

	back() {
		const a = this.navController['router'].url.indexOf('home');
		if (this.isH5 && this.navController['router'].url.indexOf('home') !== -1) {
			alert('企业微信退出首页')
			return;
		}
		// this.navController.pop();
		history.go(-1);

	}
}
